import { PATH_APP } from '../paths';

const device = {
  customFilters: [
    {
      id: 'organizations',
      type: 'operators',
      placeholder: 'Operators',
      key: 'organizations.id'
    }
  ],
  path: PATH_APP.hardware.devices,
  index: 'devices',
  title: 'Devices',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Devices' }],
  data: [
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name',
      asc: 'devices_name_asc',
      desc: 'devices_name_desc'
    },
    {
      column: {
        id: 'location',
        label: 'Location',
        alignRight: false,
        width: 180
      },
      key: 'location'
    },
    {
      column: {
        id: 'trip',
        label: 'Trip',
        alignRight: false
      },
      key: 'trip'
    },
    {
      column: {
        id: 'manufacturer',
        label: 'Manufacturer',
        alignRight: false
      },
      key: 'manufacturer'
    },
    {
      column: {
        id: 'model',
        label: 'Model',
        alignRight: false
      },
      key: 'model'
    },
    {
      column: {
        id: 'version',
        label: 'Version',
        alignRight: false
      },
      key: 'version'
    },
    {
      column: {
        id: 'battery',
        label: 'Battery',
        alignRight: false
      },
      key: 'battery',
      format: 'percentage'
    },
    {
      column: {
        id: 'operator',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    },
    {
      column: {
        id: 'updated',
        label: 'Updated',
        alignRight: false,
        width: 180
      },
      key: 'updated',
      format: 'date',
      asc: 'devices_updated_asc',
      desc: 'devices_updated_desc'
    }
    // {
    //   column: {
    //     id: 'status',
    //     label: 'Status',
    //     alignRight: false
    //   },
    //   key: 'status',
    //   component: {
    //     name: 'label'
    //   }
    // }
  ],
  plural: 'devices',
  singular: 'device',
  queryBy: 'name,location,trip,manufacturer,model,operator'
};

export default device;
