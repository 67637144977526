import { PATH_APP } from '../paths';

const exports = {
  path: PATH_APP.exports.root,
  collection: 'exports',
  index: 'exports',
  title: 'Exports',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Exports' }],
  rowOptions: {
    delete: true,
    download:
      'https://us-central1-rygo-6e9c4.cloudfunctions.net/downloadUploadedFile?type=export',
    downloadHoverTitle: 'Download export',
    create: true
  },
  submitCloudFunction: 'createExport',
  dialogTitle: 'New Export',
  includeAllOperators: true,
  dialogAlert:
    'Larger exports can take longer to complete (usually a few hours at the most). If you would prefer, you can close this screen and download the export by clicking it in the list once it has completed.',
  inputs: [
    {
      id: 'organizations',
      label: 'Organization',
      type: 'organization',
      default: {}
    },
    {
      id: 'exportType',
      label: 'Export Type',
      type: 'export-type',
      default: 'all'
    }
  ],
  snackbar: {
    title: 'Export completed'
  },
  submitText: 'Export',
  deleteDescription: 'This will delete the export from your organisation.',
  data: [
    {
      column: {
        id: 'started',
        label: 'Started',
        alignRight: false,
        width: 250
      },
      format: 'date',
      key: 'started'
    },
    {
      column: {
        id: 'finished',
        label: 'Finished',
        alignRight: false,
        width: 250
      },
      format: 'date',
      key: 'finished'
    },
    {
      column: {
        id: 'organizationsText',
        label: 'Operators',
        alignRight: false
      },
      key: 'organizationsText'
    },
    {
      column: {
        id: 'type',
        label: 'Type',
        alignRight: false
      },
      key: 'type'
    },
    {
      column: {
        id: 'user',
        label: 'User',
        alignRight: false
      },
      key: 'user'
    },
    {
      column: {
        id: 'status',
        label: 'Status',
        alignRight: false
      },
      component: {
        name: 'label'
      },
      key: 'status'
    }
  ],
  plural: 'exports',
  singular: 'export',
  queryBy: 'user,status',
  sortBy: 'started'
};

export default exports;
