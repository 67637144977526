import { PATH_APP } from '../paths';

const peripheral = {
  path: PATH_APP.hardware.peripherals,
  index: 'peripherals',
  title: 'Peripherals',
  breadcrumb: [
    { name: 'Portal', href: PATH_APP.root },
    { name: 'Peripherals' }
  ],
  data: [
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name'
    },
    {
      column: {
        id: 'battery',
        label: 'Battery',
        alignRight: false
      },
      key: 'battery',
      format: 'percentage'
    },
    {
      column: {
        id: 'operator',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    }
  ],
  plural: 'peripherals',
  singular: 'peripheral',
  queryBy: 'name,operator'
};

export default peripheral;
