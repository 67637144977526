import { PATH_APP } from '../paths';

const operators = {
  path: PATH_APP.organization.operators,
  collection: 'organizations',
  index: 'organizations',
  title: 'Operators',
  // submitCloudFunction: 'registerOperator',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Operators' }],
  rowOptions: {
    // create: true,
    edit: true,
    delete: 'unlinkOrganization'
  },
  deleteDescription:
    'This will unlink the organisation from your account. This process can take a few minutes to complete and search results may take a few additional minutes to purge deleted content.',
  data: [
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name'
    },
    {
      column: {
        id: 'contactName',
        label: 'Contact Name',
        alignRight: false
      },
      key: 'contactName'
    },
    {
      column: {
        id: 'contactPhone',
        label: 'Contact Phone',
        alignRight: false
      },
      key: 'contactPhone'
    },
    {
      column: {
        id: 'address',
        label: 'Address',
        alignRight: false
      },
      key: 'address'
    },
    {
      column: {
        id: 'website',
        label: 'Website',
        alignRight: false
      },
      key: 'website'
    },
    {
      column: {
        id: 'lastImported',
        label: 'Last Used',
        alignRight: false
      },
      format: 'date',
      key: 'lastImported'
    }
  ],
  inputs: [
    {
      id: 'name',
      label: 'Name',
      sm: 12
    },
    {
      id: 'contactName',
      label: 'Contact Name',
      sm: 12
    },
    {
      id: 'contactPhone',
      label: 'Contact Phone',
      sm: 12
    },
    {
      id: 'address',
      label: 'Address',
      sm: 12
    },
    {
      id: 'website',
      label: 'Website',
      sm: 12
    }
  ],
  plural: 'operators',
  singular: 'operator',
  queryBy: 'name,contactName,contactPhone,address,website'
};

export default operators;
