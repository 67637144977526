import { PATH_APP } from './paths';
import * as config from './config';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from 'src/components/Auth/AuthProtect';
import DashboardLayout from 'src/layouts/DashboardLayout';

// ----------------------------------------------------------------------

const AppRoutes = {
  path: PATH_APP.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // OVERVIEW
    // ----------------------------------------------------------------------
    // {
    //   exact: true,
    //   path: PATH_APP.overview.dashboard,
    //   component: lazy(() => import('src/views/general/DashboardAppView'))
    // },
    {
      exact: false,
      path: PATH_APP.exports.new,
      component: lazy(() => import('src/views/export'))
    },
    {
      exact: false,
      path: PATH_APP.imports.new,
      component: lazy(() => import('src/views/import'))
    },
    {
      exact: true,
      path: PATH_APP.overview.map,
      component: lazy(() =>
        import('src/views/uikit/extra-components/MapView/GoogleMaps')
      )
    },
    {
      exact: true,
      path: PATH_APP.root,
      component: () => <Redirect to={PATH_APP.trips.list} />
    },

    // MANAGEMENT : USER
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.user.profile,
      component: lazy(() => import('src/views/user/ProfileView'))
    },
    {
      exact: true,
      path: PATH_APP.user.cards,
      component: lazy(() => import('src/views/user/UserCardsView'))
    },
    {
      exact: true,
      path: PATH_APP.user.list,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.user.account,
      component: lazy(() => import('src/views/user/AccountView'))
    },

    // MANAGEMENT : ACCOUNT
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_APP.account.users,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.account.reports,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.account.billing,
      component: lazy(() => import('src/views/user/UserListView'))
    },
    {
      exact: true,
      path: PATH_APP.user.root,
      component: () => <Redirect to={PATH_APP.user.profile} />
    },

    ...Object.values(config)
      .map(({ path, ...props }) => {
        const routes = [
          {
            exact: true,
            path,
            component: lazy(() => import('src/components/TypesensePage')),
            props
          }
        ];

        // if (permissions && permissions.create) {
        //   routes.push({
        //     exact: true,
        //     path: `/${collection}/create`,
        //     component: lazy(() => import('src/components/EditPage')),
        //     props: { ...props, collection, edit: false }
        //   });
        // }

        // if (permissions && permissions.edit) {
        //   routes.push({
        //     exact: true,
        //     path: `/${collection}/:document/edit`,
        //     component: lazy(() => import('src/components/EditPage')),
        //     props: { ...props, collection, edit: true }
        //   });
        // }

        return routes;
      })
      .flat(),

    {
      exact: false,
      path: PATH_APP.imports.root,
      component: lazy(() => import('src/views/import'))
    },

    // {
    //   exact: false,
    //   path: PATH_APP.exports.root,
    //   component: lazy(() => import('src/views/export'))
    // },

    {
      exact: false,
      path: PATH_APP.trips.list,
      component: lazy(() => import('src/views/trip/TripView'))
    },

    // ----------------------------------------------------------------------
    {
      component: lazy(() => import('src/views/errors/Page404View'))
    }
  ]
};

export default AppRoutes;
