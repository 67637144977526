import React from 'react';
import { PATH_APP } from 'src/routes/paths';
import {
  Account,
  Map,
  MonitorDashboard,
  MapMarkerDistance,
  Domain,
  Devices,
  CardAccountDetails,
  FaceRecognition,
  MapMarkerRadius,
  Routes,
  Bus,
  AccountTie,
  Cellphone,
  Bluetooth,
  Upload,
  AccountGroup,
  AccountMultiple,
  ClipboardAccount,
  Download,
  OfficeBuilding
} from 'mdi-material-ui/';

// ----------------------------------------------------------------------

const ICONS = {
  dashboard: <MonitorDashboard />,
  trips: <MapMarkerDistance />,
  account: <Account />,
  map: <Map />,
  organization: <Domain />,
  hardware: <Devices />,
  pass: <CardAccountDetails />,
  geofence: <MapMarkerRadius />,
  scan: <FaceRecognition />,
  route: <Routes />,
  vehicle: <Bus />,
  driver: <AccountTie />,
  device: <Cellphone />,
  peripheral: <Bluetooth />,
  organizations: <OfficeBuilding />,
  import: <Upload />,
  export: <Download />,
  user: <AccountMultiple />,
  allocation: <ClipboardAccount />
};

const navConfig = [
  // OVERVIEW
  // ----------------------------------------------------------------------
  {
    subheader: 'Operations',
    items: [
      // {
      //   title: 'dashboard',
      //   icon: ICONS.dashboard,
      //   href: PATH_APP.overview.dashboard
      // },
      {
        title: 'Trips',
        icon: ICONS.map,
        href: PATH_APP.trips.list
      },
      {
        title: 'allocations',
        icon: ICONS.allocation,
        href: PATH_APP.trips.allocations
      }
      // {
      //   title: 'map',
      //   icon: ICONS.map,
      //   href: PATH_APP.overview.map
      // }
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Reporting',
    items: [
      {
        title: 'passes',
        icon: ICONS.pass,
        href: PATH_APP.data.passes,
        passengers: true
      },
      {
        title: 'scans',
        icon: ICONS.scan,
        href: PATH_APP.data.scans,
        passengers: true
      },
      {
        title: 'geofences',
        icon: ICONS.geofence,
        href: PATH_APP.data.geofences
      }
    ]
  },
  {
    subheader: 'Hardware',
    items: [
      {
        title: 'devices',
        icon: ICONS.device,
        href: PATH_APP.hardware.devices
      },
      {
        title: 'peripherals',
        icon: ICONS.peripheral,
        href: PATH_APP.hardware.peripherals
      }
    ]
  },
  {
    subheader: 'My Organisation',
    items: [
      {
        title: 'routes',
        icon: ICONS.route,
        href: PATH_APP.organization.routes
      },
      // {
      //   title: 'accounts',
      //   icon: ICONS.user,
      //   href: PATH_APP.organization.accounts
      // },
      {
        title: 'drivers',
        icon: ICONS.driver,
        href: PATH_APP.organization.drivers
      },
      {
        title: 'vehicles',
        icon: ICONS.vehicle,
        href: PATH_APP.organization.vehicles
      },
      {
        title: 'operators',
        icon: ICONS.organizations,
        href: PATH_APP.organization.operators
      },
      {
        title: 'imports (Beta)',
        icon: ICONS.import,
        href: PATH_APP.imports.root
      },
      {
        title: 'exports',
        icon: ICONS.export,
        href: PATH_APP.exports.root
      }
    ]
  }
];

export default navConfig;
