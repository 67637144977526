import { PATH_APP } from '../paths';

const vehicle = {
  customFilters: [
    {
      id: 'operators',
      type: 'operators',
      placeholder: 'Operators',
      key: 'organizations.id'
    }
  ],
  collection: 'vehicles',
  path: PATH_APP.organization.vehicles,
  index: 'vehicles',
  title: 'Vehicles',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Vehicles' }],
  dialogTitle: 'Vehicle',
  deleteDescription: 'This will delete the vehicle from your organisation.',
  rowOptions: {
    edit: true,
    delete: true
  },
  inputs: [
    {
      id: 'registration',
      label: 'Registration',
      sm: 12
    },
    {
      id: 'manufacturer',
      label: 'Manufacturer',
      sm: 6
    },
    {
      id: 'model',
      label: 'Model',
      sm: 6
    },
    {
      id: 'seats',
      label: 'Seats',
      sm: 12
    },
    {
      id: 'nfcId',
      label: 'Nfc Id',
      sm: 12,
      type: 'validator'
    }
  ],
  data: [
    {
      column: {
        id: 'registration',
        label: 'Registration',
        alignRight: false
      },
      key: 'name'
    },
    {
      column: {
        id: 'manufacturer',
        label: 'Manufacturer',
        alignRight: false
      },
      key: 'manufacturer'
    },
    {
      column: {
        id: 'model',
        label: 'Model',
        alignRight: false
      },
      key: 'model'
    },
    {
      column: {
        id: 'seats',
        label: 'Seats',
        alignRight: false
      },
      key: 'seats'
    },
    {
      column: {
        id: 'organization',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    },
    {
      column: {
        id: 'nfcId',
        label: 'Nfc Id',
        alignRight: false
      },
      key: 'nfcId'
    }
  ],
  plural: 'vehicles',
  singular: 'vehicle',
  queryBy: 'name,manufacturer,model,operator,nfcId'
};

export default vehicle;
