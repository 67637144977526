import { PATH_APP } from '../paths';

const imports = {
  path: PATH_APP.imports.root,
  collection: 'imports',
  index: 'imports',
  title: 'Imports (Beta)',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Imports' }],
  sortBy: 'uploaded',
  defaultOrder: 'desc',
  queryBy: 'user,status',
  rowOptions: {
    archive: true,
    create: true,
    download:
      'https://us-central1-rygo-6e9c4.cloudfunctions.net/downloadUploadedFile?type=import',
    downloadHoverTitle: 'Download source import file',
    template: true
  },
  data: [
    {
      column: {
        id: 'uploaded',
        label: 'Uploaded',
        alignRight: false,
        width: 250
      },
      format: 'date',
      key: 'uploaded',
      link: true
    },
    {
      column: {
        id: 'routes',
        label: 'Routes',
        alignRight: false
      },
      key: 'routes'
    },
    {
      column: {
        id: 'trips',
        label: 'Trips',
        alignRight: false
      },
      key: 'trips'
    },
    {
      column: {
        id: 'vehicles',
        label: 'Vehicles',
        alignRight: false
      },
      key: 'vehicles'
    },
    {
      column: {
        id: 'drivers',
        label: 'Drivers',
        alignRight: false
      },
      key: 'drivers'
    },
    {
      column: {
        id: 'stops',
        label: 'Stops',
        alignRight: false
      },
      key: 'stops'
    },
    {
      column: {
        id: 'author',
        label: 'User',
        alignRight: false
      },
      key: 'author'
    },
    {
      column: {
        id: 'status',
        label: 'Status',
        alignRight: false
      },
      component: {
        name: 'label'
      },
      key: 'status'
    }
  ],
  plural: 'imports',
  singular: 'import'
};

export default imports;
