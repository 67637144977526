import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import HomeLayout from 'src/layouts/HomeLayout';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('src/views/pages/ComingSoonView'))
    },
    {
      exact: true,
      path: '/register',
      component: lazy(() => import('src/views/auth/RegisterView'))
    },
    {
      exact: false,
      component: lazy(() => import('src/views/map'))
    },
    {
      component: lazy(() => import('src/views/errors/Page404View'))
    }
  ]
};

export default HomeRoutes;
