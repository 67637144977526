import { PATH_APP } from '../paths';

const pass = {
  path: PATH_APP.data.passes,
  index: 'passes',
  title: 'Passes',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Passes' }],
  customFilters: [
    {
      id: 'scanDate',
      type: 'date',
      placeholder: 'Last scan date',
      key: 'scanned'
    }
  ],
  // rowOptions: {
  //   edit: true
  // },
  // collection: 'passes',
  // inputs: [
  //   {
  //     id: 'forename',
  //     label: 'Forename',
  //     sm: 6
  //   },
  //   {
  //     id: 'surname',
  //     label: 'Surname',
  //     sm: 6
  //   }
  // ],
  data: [
    {
      column: {
        id: 'id',
        label: 'ID',
        alignRight: false
      },
      key: 'id'
    },
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false
      },
      key: 'name',
      asc: 'passes',
      desc: 'passes'
    },
    {
      column: {
        id: 'location',
        label: 'Last Location',
        alignRight: false
      },
      key: 'location'
    },
    {
      column: {
        id: 'scanned',
        label: 'Last Scanned',
        alignRight: false
      },
      key: 'scanned',
      format: 'date'
    }
  ],
  plural: 'passes',
  singular: 'pass',
  queryBy: 'name'
};

export default pass;
