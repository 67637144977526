import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  isEmpty,
  isLoaded,
  useFirebase,
  useFirestore
} from 'react-redux-firebase';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth() {
  // Firebase Auth
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { auth, profile } = useSelector((state) => state.firebase);

  // if (
  //   !profile ||
  //   !profile.token ||
  //   !profile.token.claims ||
  //   !profile.token.claims.organizations ||
  //   profile.token.claims.organizations.length < 1
  // ) {
  //   return;
  // }

  // Firebase Auth
  return {
    method: 'firebase',
    user: {
      displayName: auth.displayName || profile.displayName || '',
      email: auth.email || '',
      photoURL: auth.photoURL || profile.photoURL || '',
      phoneNumber: auth.phoneNumber || profile.phoneNumber || '',
      country: profile.country || '',
      address: profile.address || '',
      state: profile.state || '',
      city: profile.city || '',
      zipCode: profile.zipCode || '',
      about: profile.about || '',
      organization:
        (profile.token &&
          profile.token.claims &&
          profile.token.claims.organizations &&
          profile.token.claims.organizations.length > 0 &&
          profile.token.claims.organizations[0]) ||
        {},
      settings:
        (profile.token &&
          profile.token.claims &&
          profile.token.claims.portal) ||
        null,
      isPublic: profile.isPublic || false,
      typesenseKey:
        profile.token && profile.token.claims && profile.token.claims.typesense
    },
    isLoading: !isLoaded(auth),
    isAuthenticated: !isEmpty(auth),
    isUnassigned:
      isLoaded(profile) &&
      (!profile.token ||
        !profile.token.claims ||
        !profile.token.claims.organizations ||
        profile.token.claims.organizations.length < 1),

    login: ({ email }) =>
      firebase.auth().sendSignInLinkToEmail(email, {
        handleCodeInApp: true,
        url:
          !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? 'http://localhost:3001/login'
            : 'https://portal.rygo.com/login'
      }),

    loginPassword: ({ email, password }) =>
      firebase.login({
        email,
        password
      }),

    loginWithGoogle: () =>
      firebase.login({ provider: 'google', type: 'popup' }),

    loginWithFaceBook: () =>
      firebase.login({ provider: 'facebook', type: 'popup' }),

    loginWithTwitter: () =>
      firebase.login({ provider: 'twitter', type: 'popup' }),

    register: ({ email, password, firstName, lastName }) =>
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((res) => {
          firestore
            .collection('users')
            .doc(res.user.uid)
            .set({
              uid: res.user.uid,
              email: email,
              displayName: firstName + ' ' + lastName
            });
        }),

    logout: () => firebase.logout(),

    resetPassword: (email) => firebase.resetPassword(email),

    updateProfile: ({
      displayName,
      photoURL,
      phoneNumber,
      country,
      state,
      city,
      address,
      zipCode,
      about,
      isPublic
    }) =>
      firebase.updateProfile({}).then((res) => {
        firestore.collection('users').doc(res.id).set(
          {
            displayName: displayName,
            photoURL: photoURL,
            phoneNumber: phoneNumber,
            country: country,
            state: state,
            city: city,
            address: address,
            zipCode: zipCode,
            about: about,
            isPublic: isPublic
          },
          { merge: true }
        );
      })
  };
}
