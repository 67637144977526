// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: '/',
  docs: '/docs'
};

export const PATH_PAGE = {
  login: '/login',
  loginPassword: '/login-password',
  loginUnprotected: '/login-unprotected',
  register: '/register',
  registerUnprotected: '/register-unprotected',
  resetPassword: '/reset-password',
  verify: '/verify',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  overview: {
    root: '/dashboard',
    dashboard: '/dashboard',
    map: '/map'
    //drivers: '/drivers'
  },
  trips: {
    list: '/trips',
    allocations: '/allocations'
  },
  user: {
    root: '/user',
    profile: '/user/profile',
    cards: '/user/card',
    list: '/user/list',
    account: '/user/account'
  },
  data: {
    root: '/data',
    passes: '/passes',
    scans: '/scans',
    geofences: '/geofences'
  },
  imports: {
    root: '/imports',
    new: '/imports/new'
  },
  exports: {
    root: '/exports',
    new: '/exports/new'
  },
  organization: {
    root: '/organisation',
    routes: '/routes',
    drivers: '/drivers',
    vehicles: '/vehicles',
    accounts: '/accounts',
    operators: '/operators'
  },
  hardware: {
    root: '/hardware',
    devices: '/devices',
    peripherals: '/peripherals'
  },
  account: {
    root: '/account',
    users: '/account/users',
    reports: '/account/reports',
    billing: '/account/billing'
  },
  unassigned: '/unassigned'
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, '/introduction'),
  started: path(ROOTS.docs, '/getting-started'),
  // Theme UI
  color: path(ROOTS.docs, '/color'),
  typography: path(ROOTS.docs, '/typography'),
  icon: path(ROOTS.docs, '/icon'),
  shadows: path(ROOTS.docs, '/shadows'),
  components: path(ROOTS.docs, '/components'),
  tips: path(ROOTS.docs, '/tips'),

  // Development
  routing: path(ROOTS.docs, '/routing'),
  environmentVariables: path(ROOTS.docs, '/environment-variables'),
  stateManagement: path(ROOTS.docs, '/state-management'),
  apiCalls: path(ROOTS.docs, '/api-calls'),
  analytics: path(ROOTS.docs, '/analytics'),
  authentication: path(ROOTS.docs, '/authentication'),
  multiLanguage: path(ROOTS.docs, '/multi-language'),
  lazyload: path(ROOTS.docs, '/lazyload-image'),
  formHelper: path(ROOTS.docs, '/form-helper'),

  // Changelog
  support: path(ROOTS.docs, '/support'),
  changelog: path(ROOTS.docs, '/changelog')
};
