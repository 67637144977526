import { PATH_APP } from '../paths';

const trip = {
  path: PATH_APP.trips.list,
  index: 'trips',
  title: 'Trips',
  defaultFilter: 'start >= startOfToday',
  // alternativeFilter: 'start >= endOfToday',
  // alternativeIndex: 'trips_desc',
  collection: 'trips',
  breadcrumb: [{ name: 'Portal', href: PATH_APP.root }, { name: 'Trips' }],
  rowOptions: {
    edit: true,
    delete: true
  },
  sortBy: 'start',
  defaultOrder: 'asc',
  deleteDescription:
    'This will delete the trip from your organisation. Drivers will no longer be able to operate this trip using the RYGO Driver app.',
  inputs: [
    {
      id: 'name',
      label: 'Name',
      sm: 12
    },
    {
      id: 'notes',
      label: 'Notes',
      sm: 12
    }
  ],
  queryBy: 'publicId,name,operator,device',
  customFilters: [
    {
      id: 'date',
      type: 'date',
      placeholder: 'Trip date',
      key: 'start',
      default: 'today'
    },
    {
      id: 'status',
      type: 'select',
      lower: true,
      placeholder: 'Status',
      key: 'status',
      minWidth: 175,
      values: ['Live', 'Completed', 'Scheduled', 'Incomplete', 'Missed']
    },
    {
      id: 'organizations',
      type: 'operators',
      placeholder: 'Operators',
      key: 'organizations.id'
    }
  ],
  data: [
    {
      column: {
        id: 'id',
        label: 'ID',
        alignRight: false
      },
      key: 'publicId',
      link: true
    },
    {
      column: {
        id: 'name',
        label: 'Name',
        alignRight: false,
        width: 300
      },
      key: 'name',
      link: true
    },
    {
      column: {
        id: 'start',
        label: 'Departure',
        alignRight: false
      },
      format: 'date',
      key: 'start',
      asc: 'trips_start_asc',
      desc: 'trips_start_desc'
    },
    {
      column: {
        id: 'operator',
        label: 'Operator',
        alignRight: false
      },
      key: 'operator'
    },
    {
      column: {
        id: 'device',
        label: 'Device',
        alignRight: false
      },
      key: 'device'
    },
    // {
    //   column: {
    //     id: 'vehicle',
    //     label: 'Vehicle',
    //     alignRight: true
    //   },
    //   key: 'allocation.vehicle'
    // },
    {
      column: {
        id: 'stops',
        label: 'Stops',
        alignRight: true
      },
      key: 'stops'
    },
    {
      column: {
        id: 'status',
        label: 'Status',
        alignRight: false
      },
      component: {
        name: 'label'
      },
      key: 'status',
      asc: 'trips_status_asc',
      desc: 'trips_status_desc'
    }
  ],
  plural: 'trips',
  singular: 'trip'
};

export default trip;
